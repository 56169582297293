import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueTimeago from "vue-timeago";
//import router from './router'
//import store from './store/index'

import NotificationCenter from './components/NotificationCenter.vue';
import 'document-register-element/build/document-register-element'
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
//import '@/assets/css/ravenhub-custom.scss'

// Configure Vue to ignore the element name when defined outside of Vue.
Vue.config.ignoredElements = [
  'notification-center'
];

// Enable the plugin
Vue.use(vueCustomElement);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
});

// Register your component
Vue.customElement('notification-center', NotificationCenter, {
  // Additional Options: https://github.com/karol-f/vue-custom-element#options
});
