<template>
  <div>
    <div class="container settings-panel" :style="cssVars">
      <b-list-group v-for="cur in eventTypeList" :key="cur.objRef.id">
        <b-list-group-item>

          <b-card :header="cur.name">
            <b-card-text>

              <b-form-checkbox v-model="cur.switchVal" @change="saveSetting(cur.objRef, cur.switchVal, $event)" switch :id="cur.objRef.id" class="switchbox">
                {{cur.description}}
              </b-form-checkbox>
              
            </b-card-text>
            <div v-if="usingSlack">
              <b-button v-if="!cur.connected" variant="outline-secondary" @click="connectSlack(cur.objRef.id)">Connect Slack <img class="slackicon" src="https://cdn.brandfolder.io/5H442O3W/as/pl546j-7le8zk-199wkt/Slack_Mark.svg"/></b-button>
              <p v-if="cur.connected">{{cur.channel}} <img class="slackicon" src="https://cdn.brandfolder.io/5H442O3W/as/pl546j-7le8zk-199wkt/Slack_Mark.svg"/> <a href="#" @click="disconnectSlack(cur.objRef)" title="Disconnect Slack"><b-icon icon="trash" variant="danger"></b-icon></a></p>
            </div>
          </b-card>
        </b-list-group-item>
      </b-list-group>
 
    </div> 
  </div>
</template>

<script>

var Parse = require('parse/node');
Parse.initialize(process.env.VUE_APP_PARSE_APP_ID, process.env.VUE_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL;

export default {
  name: 'ControlPanelComponent',
  components: {

  },
  props: {
    appid : String, 
    subscriberid : String,
    fontcolor: {
      type: String,
      default: "#0099CC"
    },
    fontfamily: {
      type: String,
      default: "avenir"
    }
  },

  data: function () {
    return {
      eventTypeList: [],      
      switch1: true,
      subscriberObj: "",
      usingSlack: false
    }
  },

  computed: {
    cssVars() {
      return {
        '--font-color': this.fontcolor,
        '--font-family': this.fontfamily
      }
    }
  },

  created() {
    console.log('created');
    this.fetchEventTypes();
    this.fetchCompany();
  },
  
  methods: {
    fetchEventTypes() {
      console.log('appId = ', this.appid);
      //const Company = Parse.Object.extend('Company');
      const Subscriber = Parse.Object.extend('Subscriber');
      const EventTypeInstance = Parse.Object.extend("EventTypeInstance");
      //const EventType = Parse.Object.extend("EventType");

      //var companyPointer = Company.createWithoutData(this.appid);
      //var subscriberPointer = Subscriber.createWithoutData(this.subscriberid);
    

      const subsriberQuery = new Parse.Query(Subscriber);
      subsriberQuery.equalTo("CompanyId", { "__type": "Pointer", "className": "Company", "objectId": this.appid });
      subsriberQuery.equalTo("SubscriberId", this.subscriberid);
      subsriberQuery.first()
      .then((subscriberResult) => {
        console.log('sub result = ' + subscriberResult);
        if (typeof subscriberResult === 'undefined') {
          //this.startFreshSubscriber();
          return;
        }
        const query = new Parse.Query(EventTypeInstance);
        query.equalTo("CompanyId", { "__type": "Pointer", "className": "Company", "objectId": this.appid });
        
        query.equalTo("SubscriberId", subscriberResult);
        query.include("EventTypeId");
        query.ascending("Name");
        query.find()
        .then((eventTypeList) => {
          if (eventTypeList.length > 0) {
            this.setupEventTypeList(eventTypeList);
          } else {
            // Start fresh
            //this.startFreshSubscriber();
          }
        }), (error) => {
          console.error('Error when fetching event type list: ' + error);
        }
      }, (error) => {
        console.log('Error fetching subscriber.  Need to create one fresh - ' + error);
        //this.startFreshSubscriber();
      });

  
      
    },

    fetchCompany() {
      const Company = Parse.Object.extend('Company');
      const companyQuery = new Parse.Query(Company);
      companyQuery.equalTo("objectId", this.appid);
      companyQuery.first().then((companyResult) => {        
        this.usingSlack = companyResult.get("SlackEnabled");
      });
    },

    startFreshSubscriber() {
      console.log('starting fresh');
      const Company = Parse.Object.extend('Company');
      const Subscriber = Parse.Object.extend('Subscriber');
      const EventTypeInstance = Parse.Object.extend("EventTypeInstance");
      const EventType = Parse.Object.extend("EventType");

      var companyPointer = Company.createWithoutData(this.appid);
      // Create new subscriber
      const newSub = new Subscriber();
      newSub.set('CompanyId', companyPointer);
      newSub.set('SubscriberId', this.subscriberid);
      newSub.save().then((savedSubscriber) => {
        // Create EventTypeInstances for new subscriber
        let eventInstanceList = [];

        const query2 = new Parse.Query(EventType);
        query2.equalTo("CompanyId", { "__type": "Pointer", "className": "Company", "objectId": this.appid });
        query2.equalTo("Type", "Event");
        query2.ascending("Name");
        query2.find().then((resultList) => {
          resultList.forEach(cur => {
            var eventPointer = EventType.createWithoutData(cur.id);
            const newInstance = new EventTypeInstance();
            newInstance.set('SubscriberId', savedSubscriber);
            newInstance.set('IsActive', true);
            newInstance.set('CompanyId', companyPointer);
            newInstance.set('EventTypeId', eventPointer);
            eventInstanceList.push(newInstance);
            console.log('adding to instance list: ' + newInstance);
          });

          console.log('instance list = ' + eventInstanceList);

          // Save instance list
          Parse.Object.saveAll(eventInstanceList)
          .then((list) => {
              console.log('All the objects were saved.');
              this.setupEventTypeList(list);
          }, (error) => {
              console.log('An error occurred while saving one of the objects.' + error);
          });
        });
      })
      .catch((function (e) {
        console.log("Error saving new subscriber! " + e.message);
      }));
    },

    setupEventTypeList(listToConvert) {
      listToConvert.forEach(cur => {
        let channelName = cur.get('channelName');
        let isConnected = (channelName) ? true : false;
        // get name and description from EventType pointer
        //console.log('***** name from pointer = ' + cur.get('EventTypeId').get('Name'));
        let curWrapper = {
                          switchVal : cur.get('IsActive'), 
                          objRef : cur,
                          connected : isConnected,
                          channel : channelName,
                          name: cur.get('EventTypeId').get('Name'),
                          description: cur.get('EventTypeId').get('Description')
                        }
        this.eventTypeList.push(curWrapper);                         
      });
      // sort list
      this.eventTypeList.sort(function(a,b) {  
        return a.name < b.name ? -1 : 1;
      });
    },

    saveSetting(obj, switchValue, event) {
      let instanceToUpdate = obj;
      console.log('event = ' + event);
      //localStorage.setItem('myevent', event);
      console.log('**** switchVal = ' + switchValue);
      instanceToUpdate.set('IsActive', event);

      instanceToUpdate.save().then((result) => {
        console.log('instance saved - ' + result);
      }), (error) => {
        console.error('Error saving instance ' + error);
      }
    },

    connectSlack(instanceId) {
      // get client id from company
      const Company = Parse.Object.extend("Company");
      const companyQuery = new Parse.Query(Company);
      companyQuery.equalTo("objectId", this.appid);
      companyQuery.first().then((companyResult) => {        
        const clientId = (companyResult.get("UsingOwnSlackApp")) ? companyResult.get("SlackClientId") : process.env.VUE_APP_SLACK_DEFAULT_CLIENT_ID;
        let stateObj = { 'eventTypeInstanceId' : instanceId,
                        'appId' : this.appid,
                        'subscriberId' : this.subscriberid, 
                        'redirUrl' : window.location.href }
        let stateString = JSON.stringify(stateObj);
        console.log('stateString = ' + stateString);
        let stateEncoded = btoa(stateString);
        console.log('stateEncoded = ' + stateEncoded);
        
        let authUrl = 'https://slack.com/oauth/authorize?scope=incoming-webhook&client_id=' + clientId +
                        '&scope=incoming-webhook&redirect_uri=https://app.ravenhub.io/slack-auth';
        authUrl = authUrl += '&state=' + stateEncoded;
        console.log('authUrl = ' + authUrl);
        window.open(authUrl, '_self');
      });
      
    },

    disconnectSlack(eventTypeObj) {
      eventTypeObj.set('webhookUrl', '');
      eventTypeObj.set('channelName', '');
      eventTypeObj.set('accessToken', '');
      eventTypeObj.save().then((eventTypeSaved) => {
        console.log('eventTypeSaved = ' + eventTypeSaved);
        this.eventTypeList = [];
        this.fetchEventTypes();
      }, (error) => {
        console.error('error disconnecting slack: ' + error);
      });
      
    }
    
  }
}
</script>

<style scoped >
div {
  color: var(--font-color);
  font-family: var(--font-family);
}
.settings-panel {
  padding: 0 !important;
  min-height: 160px;
  max-height: 450px;
}
.settings-header {
  font-size:1em;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
}
.slackicon {
  width: 32px;
}
.card {
  box-shadow:0px 0px 10px -4px #607D8B;
}
.card-text {
  color:#676767;
  font-size:14px;
}
.card-title {
  font-size: 18px;
}
.list-group {
  width: 100%;
}
.list-group-item {
  border:none;
  padding:0.25rem 0.75em;
}
.switchbox >>> .custom-control-label {
  cursor: pointer;
}
.switchbox >>> .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #42b983;
    background-color: #42b983;
}
</style>